@use "../../abstracts";

.header-navbar{
  @include abstracts.flexbox-vertical-align;
  justify-content: space-between;
  padding: 15px 15px 5px 15px;
  svg{
    color: abstracts.$primary-main;
    font-size: 30px;

  }
  &__logo-menu{
    @include abstracts.flexbox-vertical-align;
    width: 45%;

    img{
      width: 50px;
      height: 50px;
      margin-right: 10px;
    }

  }
  &__options{
    @include abstracts.flexbox-vertical-align;
    width: 55%;
    justify-content: space-around;

    &-item{
      @include abstracts.flexbox-vertical-align;
      flex-direction: column;
      p{
        color: abstracts.$gray-200;
        margin: 0;
        font-weight: 600;
      }
    }
  }
}