/*
 *==================================================
 * Licensed Materials - Property of HCL Technologies
 *
 * HCL Commerce
 *
 * (C) Copyright HCL Technologies Limited 2020
 *
 *==================================================
 */
/**
* CSS Reset
*/

//Custom styled-drawer-hdm
//@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

html {
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

a:-webkit-any-link {
  text-decoration: none;
}

header {
  padding-right: 10px;
  padding-left: 10px;
}

footer {
  padding-left: 10px;
  padding-right: 10px;
}

// iframe {
//   pointer-events: none;
// }

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

$images-dir: "^images/";

.accordion-title:focus {
  background-color: transparent;
}

/**
* Call out
*/
// call out additional styled-drawer-hdm to fix close button overlaying text
.callout {
  & > *:first-child:not(.close-button) {
    display: inline-flex;
    margin-right: 1rem;
  }
}

.bundle-qty {
  vertical-align: middle;

  @media not screen and (max-width: 40em) {
    max-width: 4rem;
  }
}

@media screen and (max-width: 58.875em) {
  .hideOnMobile {
    display: none;
  }
}

.promo-large {
  position: relative;
  overflow: hidden;
  height: 85vh;
  background-position: 34% 0;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 640px) {
    height: 0;
    padding-top: 41.6667%;
  }

  img.promo-img {
    position: absolute;
    top: 0%;
    height: 100%;

    @media (max-width: 640px) {
      height: 100%;
      max-width: none;
    }
  }

  .promo-border {
    outline: 3px solid #fff;
    outline-offset: -20px;
  }
}

.promo-large-message {
  position: absolute;
  top: 10%;
  left: 10%;
  margin-right: 10%;

  .promo-large-text {
    font-size: 1.625rem;
    /*24px*/
    line-height: 1.2;
    font-weight: 600;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  }
}

/* Medium promo. Full browser width. Medium promo is meant to be placed in a
3-column-wide area. Hidden on small screens. */
.promo-medium {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6c6c6c;
  height: 40px;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease-in-out;

  img {
    position: absolute;
    top: 0%;
    left: 0;

    height: 100%;
  }

  @media (min-width: 640px) {
    display: static;
    position: relative;
    height: 0;
    padding-top: 16.6667%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @media (max-width: 640px) {
    position: relative;
  }
}

.promo-medium-message {
  color: #fff;

  @media (min-width: 39.9375em) {
    width: 100%;
    position: absolute;
    top: 40%;
    left: 0;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    color: #fff;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  }

  @media screen and (min-width: 40em) and (max-width: 63.9375em) and (orientation: landscape) {
    top: 35%;
  }

  @media (min-width: 63.9375em) {
    font-size: 1.875rem;
    /*30px*/
  }

  > p {
    margin: 0;
  }
}

/* Medium promo. Full browser width. Medium promo is meant to be placed in a
3-column-wide area. Hidden on small screens. */
.promo-small-container {
  position: relative;
  width: 100%;

  //In promotion image profile, small rendition has dimension ( 285px by 297px)
  max-width: 285px;
  max-height: 297px;

  .promo-small {
    position: relative;
    height: 0;
    padding-top: 75.1%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    justify-content: center;
    align-items: center;

    img {
      position: absolute;
      top: 0%;
      display: block;
      height: auto;
      overflow: hidden;
    }

    .promo-border {
      outline: 3px solid black;
      outline-offset: -27px;
    }

    .promo-small-message {
      position: absolute;
      padding: 6px 10px;
      width: 100%;
      font-size: 1.625rem;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      left: 0;

      > p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .promo-text-small {
    font-size: 1.375rem;
    /*22px*/
    font-weight: 700;
    line-height: 1.2;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    color: black;

    @media (min-width: 900px) {
      font-size: 1.625rem;
      /*26px*/
    }
  }
}

.wch-hero-image {
  overflow: hidden;
  position: relative;
  text-align: center;

  @media (max-width: 639px) {
    position: relative;
    height: 85vh;
    overflow: hidden;
  }

  .section {
    /*Heroes touch the header, remove top margin.*/
    padding-top: 0;
    display: flex;
  }

  &.wch-hero-image-message-right .hero-message > div {
    text-align: right;
  }

  &.wch-hero-image-message-left .hero-message > div {
    text-align: left;
  }

  &.wch-hero-image-message-center .hero-message > div {
    text-align: center;
  }

  img {
    max-width: 100%;
    transition: transform 0.3s ease-in;

    position: relative;
    z-index: 1;

    @media (max-width: 639px) {
      transform: translate3d(-18%, 0, 0);
      height: 100%;
      max-width: none;
    }

    @media (max-width: 39.9375em) {
      width: auto;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .foreground-image {
    position: absolute;
    z-index: 2;
  }
}

.hero-message {
  top: 0;
  right: auto;
  left: auto;
  /*centering overlaid message*/
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  max-width: 1200px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5), 0 -1px 0 rgba(255, 255, 255, 0.5), 1px 0 0 rgba(255, 255, 255, 0.5),
    -1px 0 0 rgba(255, 255, 255, 0.5);

  position: absolute;
  z-index: 2;
  margin-left: 1rem;
  margin-right: 1rem;

  @media (max-width: 39.9375em) {
    justify-content: flex-start;
    padding-top: 22vh;
  }

  > div {
    padding: 0 1rem;

    @media (min-width: 780px) {
      padding: 0 2rem;
    }

    @media (min-width: 1024px) {
      padding: 0 3rem;
    }
  }

  .text-hero-left {
    text-align: left;
  }

  .text-hero-right {
    text-align: right;
  }

  .text-hero-center {
    text-align: center;
  }
}

h1.text-hero {
  font-size: 2rem;
  line-height: 1.2;
  color: #000;
  font-family: Roboto, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;

  font-weight: 600;

  @media (min-width: 640px) {
    font-size: 2.5rem;
    line-height: 1.2;
  }

  @media (min-width: 1024px) {
    font-size: 4rem;

    h1 {
      max-width: 1200px;
    }

    > .button {
      margin-top: 64px;
    }
  }
}

.text-left {
  text-align: left;
}

/**
* Global mobile fixes
*/

// fix image overlaying border on safari and mobile
img {
  vertical-align: inherit;
}

.swatch-image {
  vertical-align: middle;
}

/* eSpot styling */
.increaseTextContrast {
  .MuiTypography-root {
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 0px 30px white;
    display: inline-block;
    border-radius: 6px;
    color: black;
  }
}

.textShadow {
  .MuiTypography-root {
    text-shadow: 0 0 0.3em white;
    color: black;
  }
}

.marketing-heroImage {
  overflow: hidden;
  height: 400px;
  background-size: cover;

  @media (min-width: 800px) {
    height: 500px;
  }

  @media (min-width: 1100px) {
    height: 600px;
  }

  @media (min-width: 1900px) {
    height: 700px;
  }

  .MuiTypography-h2 {
    font-weight: 400;
  }

  .marketing-text {
    left: 20px;

    @media (min-width: 600px) {
      left: 40px;
    }

    @media (min-width: 1000px) {
      left: 60px;
    }
  }

  img {
    opacity: 0.75;

    @media (min-width: 640px) {
      opacity: 0.8;
    }

    @media (min-width: 780px) {
      opacity: 0.85;
    }

    @media (min-width: 900px) {
      opacity: 0.9;
    }
  }
}

.marketing-fullscreen-banner {
  img {
    position: absolute;
  }
}

.HomeHeroContent {
  .marketing-text {
    background: rgba(255, 255, 255, 0.6);
    border: 2px solid white;
    border-radius: 6px;

    @media (max-width: 799px) {
      width: 100%;
      max-width: 100%;
      left: 0;
    }
  }

  .MuiTypography-root {
    display: inline;
  }

  .MuiTypography-h2 {
    font-weight: 700;
  }

  .marketing-text {
    padding: 20px 24px;
    left: 0;

    @media (min-width: 960px) {
      padding: 24px 30px;
      left: 40px;
    }

    @media (min-width: 1320px) {
      left: 0;
    }
  }
}

.FreeDeliveryContent {
  height: 200px;
  border-radius: 6px;
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.1), 0px 2px 1px 0px rgba(0, 0, 0, 0.25);

  @media (min-width: 600px) {
    height: 240px;

    .marketing-text {
      padding: 16px 32px;
    }
  }

  @media (max-width: 599px) {
    .marketing-text {
      padding: 8px 16px;
    }
  }

  img {
    opacity: 0.4;
  }

  .marketing-text {
    width: 600px;
    max-width: 100%;
    text-align: center;
    padding: 8px 16px;
    font-weight: 500;
    color: black;

    @media (min-width: 600px) {
      padding: 16px 32px;
    }
  }

  .MuiTypography-h4 {
    font-weight: 500;
  }
}

.PercentOffContent {
  border-radius: 6px;
  box-shadow: 2px 3px 3px #ddd;
  margin-top: 16px;
  background: white;

  .MuiGrid-item:last-child {
    min-height: 120px;
    position: relative;
    margin-top: 8px;
  }

  .MuiTypography-h4 {
    font-weight: 500;
  }
}

.SapphirePercentOffContent {
  color: white;
  height: 200px;
  border-radius: 6px;
  font-weight: 500;

  @media (min-width: 600px) {
    height: 400px;
  }

  .marketing-text {
    max-width: 350px;
  }
}

.SapphireOrderManagement {
  height: 200px;
  border-radius: 6px;

  @media (min-width: 600px) {
    height: 240px;

    .marketing-text {
      padding: 16px 32px;
    }
  }

  @media (max-width: 599px) {
    .marketing-text {
      padding: 8px 16px;
    }
  }

  img {
    opacity: 0.9;
  }

  .marketing-text {
    width: 600px;
    max-width: 90%;
    padding: 0 16px 8px;
    font-weight: 500;
    color: black;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 6px;

    @media (min-width: 600px) {
      padding: 8px 20px 16px;
    }
  }

  .MuiTypography-h4 {
    font-weight: 400;
  }
}

.marketing-backgroundImage {
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    vertical-align: middle;
  }
}

.marketing-content {
  position: relative;
  overflow: hidden;
}

.marketing-text {
  &.marketing-text-vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &.marketing-text-horizontal-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &.marketing-text-horizontal-center.marketing-text-vertical-center {
    transform: translateX(-50%) translateY(-50%);
  }
}

.category-recommendation {
  img {
    display: block;
  }
}

.MuiPopover-root {
  .StyledTableFilterMenu {
    top: 50% !important;
    left: 50% !important;
  }
}

.table-filter {
  .MuiTypography-body2 {
    font-weight: 600;
    font-size: 1.05rem;
  }
}

.bundle-error {
  .MuiTypography-body2.error {
    font-weight: 600;
    font-size: 0.8rem;
  }

  &:last-child {
    float: right;
  }
}

li.MuiBreadcrumbs-li:last-child {
  overflow: hidden;
}

.table-tablet {
  @media screen and (min-width: 40em) and (max-width: 64em) {
    > thead {
      > tr {
        > th {
          padding: 0.2rem;
        }
      }
    }

    > tbody {
      > tr {
        > td {
          padding: 0.2rem;
        }
      }
    }
  }
}

.cprof-cr8-acrdn,
.rl-cr8-acrdn,
.rl-upload-acrdn,
.ip-cr8-acrdn {
  > .MuiAccordionSummary-content {
    > .MuiBox-root {
      flex-wrap: nowrap;
    }
  }
}

.hidden-tooltip {
  display: none;
}

.error-tooltip {
  > div.MuiTooltip-tooltip {
    background-color: white;
    font-size: 0.7rem;
    border-style: solid;
    border-width: 1px;
    border-color: crimson;
    color: crimson;

    > span.MuiTooltip-arrow {
      color: crimson;
    }
  }

  font-size: 2rem;
}

.accordion-icon-text {
  p {
    @media screen and (max-width: 40em) {
      display: none;
    }
  }
}

.shipment-group-summary {
  // panel-text
  > .MuiAccordionSummary-content {
    > .shipment-group-summary-text {
      margin-top: 0.25rem;

      p {
        word-break: break-word;
        white-space: normal;
      }
    }
  }

  // panel-expander (icon+text)
  > .MuiAccordionSummary-expandIcon.MuiButtonBase-root.MuiIconButton-root {
    margin-top: 0.25rem;

    > .MuiIconButton-label {
      > .accordion-show-summary,
      .accordion-show-expanded {
        @media screen and (max-width: 40em) {
          display: none;
        }
      }
    }
  }
}

p.MuiTypography-root.StyledNumberInput {
  @media screen and (min-width: 40em) {
    max-width: 10rem;
  }
}

.break-word {
  word-break: break-word;
}

.text-right {
  text-align: right;
}

table.inner-borders {
  tr:not(:last-child) {
    border-bottom-style: solid;
    border-bottom-width: thin;
  }

  td:not(:last-child),
  th:not(:last-child) {
    border-right-style: solid;
    border-right-width: thin;
    border-right-color: inherit;
  }
}

// allow the attr drawer table div to wrap text anywhere so that the table doesn't
//   go beyond the visual view-port -- alternative is to track the order-item-table
//   thru a ref and programmatically set max-width on the drawer td (in pixels since
//   percentages don't work) and then allow a scroll on the attr drawer table div
table.order-item-table,
table.list-item-table {
  > tbody > tr > td > div.MuiPaper-root.order-item-table-drawer {
    margin: -8px;

    > table {
      border: 0;

      > thead > tr > th {
        padding: 8px;
      }

      > tbody {
        border: 0;

        > tr {
          border: 0;

          > td {
            padding: 8px;

            // in mobile mode, when displaying attr drawer, give more room at the bottom
            //   for pivoted labels (that are long) to display -- give less room at the
            //   top to create space -- alternative requires programmatically adjusting
            //   the td height thru javascript
            @media screen and (max-width: 40em) {
              padding: 4px 8px 24px;

              > div.tdBefore {
                display: inline-flex;
                position: initial;
                margin-left: calc(-100% - 10px);
                margin-right: calc(50% + 30px);
              }

              > span.td-inline-content {
                display: inline-flex;
              }
            }
          }
        }
      }
    }
  }
}

.dialog--minimal {
  .MuiDialogTitle-root {
    display: flex;
    align-items: center;

    > button.MuiButtonBase-root {
      position: relative;
      padding: 0 0 0 8px;
      top: 0;
      right: 0;
    }
  }

  .MuiDialogContent-root {
    border-top: 0;
    padding-top: 0;

    .MuiDialogActions-root {
      display: flex;
      justify-content: space-evenly;
      flex-direction: row-reverse;
      padding-top: 0;

      > button {
        min-width: 150px;
      }
    }
  }
}

.add-to-rl {
  align-items: flex-end;
}

.button-combo {
  &:first-child {
    justify-content: flex-end;

    .add-to-rl {
      align-items: flex-end;
    }
  }

  &:not(:first-child) {
    .add-to-rl {
      align-items: flex-start;
    }
  }
}

// where necessary, adjust pivoted display for table
table.better-pivot {
  @media screen and (max-width: 40em) {
    > tbody > tr > td.pivoted {
      > div.tdBefore {
        display: inline-flex;
        position: initial;
        margin-left: calc(-100% - 10px);
        margin-right: calc(50% + 30px);
      }

      > * {
        display: inline-flex !important;
      }
    }
  }
}

// .promo_plp-ribbon-hv {
//   width: 25px;
//   height: 25px;
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   margin-right: 5px;
//   margin-left: 5px;
//   margin-top: 5px;
//   margin-bottom: 5px;
// }

div.MuiPaper-root.compare-product-table-container {
  box-shadow: none;
  background-color: transparent;

  > table {
    background: transparent;
    border-collapse: unset;

    > tbody > tr {
      background: transparent;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}

#CartPage > div:first-child {
  width: 100%;
  margin: 0px;
}
