@use '../../abstracts';

.MuiDrawer-paper {
    //position: relative;
    width: 100% !important;
    max-width: 900px !important;
    overflow-x: hidden;
}

.MuiDrawer-root {
    //position: absolute;
    width: 100%;
    max-width: 900px !important;
}

.drawer {
    &__cartHeader-menu {
        @include abstracts.flexbox-centered-align;
        //margin: 0 10px 10px 10px;
        padding: 0 0 15px 10px;
        border-bottom: 1px solid abstracts.$gray-200;

        span {
            font-weight: bold;
            font-size: 1rem;
            margin: 11px 0 0 0px;
        }
    }

    &__icon-close {
        position: absolute;
        right: 5px;
        top: 15px;
        color: abstracts.$primary-main;
        cursor: pointer;
        font-size: 2rem;
    }

    &__content {
        //border-top: 2px solid abstracts.$primary-main;
        width: 100%;
        //max-height: 400px;
        //position: relative;
        z-index: -1;
    }

    &__no-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span {
            margin-top: 15px;
        }



        .__no-item-cart-icon {
            color: black;
            font-size: 90px;
        }

    }


}