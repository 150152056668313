
@use "../../abstracts";

.header-address{
  @include abstracts.flexbox-vertical-align;
  align-items: flex-end;
  position: relative;
  height: 68px;
  margin: 5px;
  @media screen and (max-width: abstracts.$medium){
    width: 100%;
    margin: 0;
    padding: 5px 10px 10px 10px;
    height: auto;
  }
  &__container-logo{
    width: 82px;
    height: 68px;
    cursor: pointer;
    img{
      height: 100%;
      width: 100%;
    }
  }
  &__info {
    max-width: 235px;
    width: 100%;
    font-size: .8rem;
    font-weight: 600;
    @include abstracts.flexbox-vertical-align;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
    p{
      margin: 0;
      cursor: pointer;
      line-height: 1.2;
    }
    @media screen and (max-width: abstracts.$medium){
      max-width: 100%;
      margin: 0;
      line-height: .7;
    }
    &-store{
      font-weight: 600;
      margin: 0;
      @media screen and (max-width: abstracts.$medium){
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
      }
      &-location{
        @include abstracts.flexbox-vertical-align;
        margin: 0;
        &-icon{
          font-size: 13px;
          color: abstracts.$success-main;
        }
      }
      div{
        @include abstracts.flexbox-vertical-align;
          button{
            @include abstracts.flexbox-vertical-align;
            padding-top: 3px;
            padding-bottom: 0;
            margin-left: 5px;
            font-size: .7rem;
            color: abstracts.$secondary-dark;
            cursor: pointer;
            line-height: 1;
            svg{
              font-size: large;
            }
          }
        }
        &-city{
          font-weight: bold;
          @include abstracts.flexbox-vertical-align;
        }
      }
      &-open-close{
        margin-top: 10px;
        span{
          color: abstracts.$success-main!important;
        }
      }


    @media screen and (max-width: abstracts.$medium){
      flex-direction: column;
    }
  }
}