@use '../../abstracts';


.list-item{
  width: 95%;
  margin: 0 0 0 2.5%;
  border-bottom: 1px solid abstracts.$gray-50;
  padding: 13px 10px!important;
  &:hover{
    .MuiSvgIcon-root{
      color: abstracts.$primary-main!important;
    }
  }
  &__icon{
    min-width: 30px;
    transition: all ease-in-out .1s;
    .MuiSvgIcon-root{
      color: abstracts.$gray-200;
      width: 20px;
      height: 20px;
    }
  }
  &__text{
    color: abstracts.$gray-500;
  }
}


