
@use "../../abstracts";

.footer-spots{

  &__container{
    padding: 5px!important;

    .MuiPaper-root{
      border-radius: 0;
      padding: 0;
      margin-bottom: 0;
    }

    &-accordion{
      .MuiAccordionSummary-content{
        padding: 10px!important;
        @media screen and (min-width: 960px) and (max-width:1340px){
          height: 68px;
        }
        h5{
          color: abstracts.$gray-500;
        }
        @include abstracts.flexbox-vertical-align;
        padding: 0 10px;
        margin: 0;
      }
      .MuiAccordionSummary-root{
        border-bottom: 1px solid abstracts.$gray-100;
        min-height: 47px;
        padding: 0;
      }
      .MuiAccordionDetails-root{
        padding: 0;
      }
      .MuiAccordionSummary-content.Mui-expanded {
        // min-height: 68px !important;
      }
      .MuiIconButton-label{
        color: abstracts.$primary-main;
      }
      .MuiIconButton-edgeEnd {
        padding: 10px 0;
        margin: 0 15px;
    }
    }
  }
}