/*Below sentences will be used to restrict 360 View when
    product details is called from a mobile device*/
@media (max-width: 600px) {
  .hide-view {
    visibility: hidden;
  }
}

@media (max-width: 1023px) {
  .hide-view {
    visibility: hidden;
  }
}

@media (max-width: 915px) and (orientation: landscape) {
  .hide-view {
    visibility: hidden;
  }
}

.vr-box {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  line-height: 1.2;
  padding: 10px;
  min-width: 320px;
}

.vr-box--gapL {
  gap: 20px;
}

.vr-button {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}
.vr-icon--contained {
  width: 25px;
  height: 25px;
  display: inline-block;
  border-radius: 50%;
  background-color: #3e7697;
  margin-right: 5px;
}
.separator-top {
  border-top: 1px solid #eee;
}
.vr-icon--ar:before {
  content: url("https://cdn.homedepot.com.mx/assets/icons/ar-iconBlanco.svg");
  display: block;
  height: 25px;
  width: 25px;
}
.vr-icon--360:before {
  content: url("https://cdn.homedepot.com.mx/assets/icons/360-iconBlanco.svg");
  display: block;
  height: 20px;
  width: 20px;
  padding: 3px;
}
.vr-icon--scene {
  width: 45px;
  height: 45px;
}
.roomvo-cta {
  font-weight: 600;
  display: inline-flex;
  width: 100%;
}
.roomvo-p {
  font-size: 12px;
  margin: 0;
}

.vr-arrow-btn {
  fill: #f96302;
  outline: #f96302 solid 1px;
  height: 35px;
  min-width: 25px;
}

.roomvo-visual-links-container {
  height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
}
