

$primary-light: #FD8331;
$primary-main: #F96302;
$primary-dark: #C14C00;
$primary-contrast-text: #FFFFFF;

$secondary-light: #5796BA ;
$secondary-main:#3E7697;
$secondary-dark:#325F7A;
$secondary-contrast-text:#FFFFFF;

$gray-50:#EEEEEE;
$gray-100:#CCCCCC;
$gray-200:#999999;
$gray-300:#777777;
$gray-400:#55565A;
$gray-500:#333333;


$success-light:#BAF0D4;
$success-main:#00A950;
$success-dark:#088944;

$error-light:#FFDAD4;
$error-main:#DB1825;
$error-dark:#AF0D18;
$error-contrastText:#FFFFFF;

$warning-light:#FFF2C9;
$warning-main:#A1803C;
$warning-dark:#80652F;
$warning-contrastText:#FFFFFF;


$text-primary:#333333;
$text-secondary:#777777;
$text-disable:#999999;
$text-highlight:#00A950;
$text-alert:#A1803C;
$text-expandedMenu:#FFFFFF;


$border-main:#cccccc;
$border-hover:#F0F4F7;
$border-alert:#C10C0D;

$divider-light:#EEEEEE;
$divider-main:#CCCCCC;
$divider-dark:#999999;

$background-default:#EEEEEE;
$background-paper:#FFFFFF;
$background-transparency:CC;

$disabled-background:#EEEEEE;
