@use "../../abstracts";

.footer-inferior {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  &__container {
    @include abstracts.flexbox-vertical-align;
    margin-bottom: 0px;

    @media screen and (max-width: 1439px) {
      flex-direction: column;
      margin-bottom: 20px;
    }

    .MuiTypography-root {

      @media screen and (max-width: abstracts.$medium) {
        //font-size: 1rem;
        margin-bottom: 0px;
      }
    }

    &-icons {
      @include abstracts.flexbox-vertical-align;
      width: 100%;
      justify-content: center;
      max-width: 300px;
      img {
        width: 24px;
      }
      div {
        margin-right: 15px;
      }
      div:last-child {
        margin-right: 0px;
      }
      @media screen and (max-width: 1439px) {
        div{
          margin-top: 10px;
        }
      }

      @media screen and (max-width: abstracts.$medium) {
        justify-content: center;
        width: 60%;
      }

      div {
        background-color: abstracts.$primary-light;
        @include abstracts.flexbox-vertical-align;
        padding: 3px;
        color: white;

        a {
          margin: 0;
          color: white;
          text-decoration: none;
          width: abstracts.$iconSmallWandH;
          height: abstracts.$iconSmallWandH;

          svg {
            color: white;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }

  &__terms {

    .MuiTypography-root {
      //font-size: .9rem;
      line-height: 1.1;
    }

    @media screen and (max-width: 1439px) {
      padding: 0;
      text-align: center;
    }

    @media screen and (max-width: abstracts.$medium) {
      padding: 0;
      text-align: center;

      .MuiTypography-root {
        display: flex;
        width: 90%;
        margin: 0 auto;
        text-align: center;
        // margin-bottom: 20px;
      }
    }
  }
}