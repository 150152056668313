@use "../../abstracts";

.footer-sup {
  background-color: white;
  margin: 10px 0 20px 0;
  box-shadow: 0px 1px 4px 2px rgb(0 0 0 / 5%), 0px 2px 1px 0px rgb(0 0 0 / 3%);

  button {
    height: 42px;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 0px !important;
  }

  .MuiFormControl-root {
    margin-top: 0;
    margin-bottom: 0;
  }


  &__grid-up-container {
    margin-bottom: 5px;
    margin-top: 5px;

    &-left,
    &-right {
      color: abstracts.$gray-500;
      padding: 10px 15px;

      &-button {
        justify-content: space-evenly;
        align-items: center;

        &:hover {
          img {
            filter: brightness(0) invert(1);
          }
        }
        @media screen and (min-width: 962px) and (max-width:1140px){
          .MuiTypography-root{
            font-size: 13.5px;
          }
        }
      }

      @media screen and (max-width: abstracts.$medium) {
        padding: 5px 10px;

        p {
          margin-top: 5px;
          line-height: 1.2;
        }

        //span{
        //  font-size: 1rem;
        //  line-height: 1.1;
        //}
        .w-grid {
          width: 85%;
        }

        div {
          flex-wrap: nowrap;
        }
      }

      .MuiFormControl-root {
        width: 96%;
      }

      .MuiInputBase-root,
      .MuiOutlinedInput {
        height: 42px;
        padding: 10px;
        margin-bottom: 0px !important;
        font-size: 1rem;
        border-radius: 0;
        width: 100%;
      }

      &-terminos {
        //font-size: .85rem;
        padding-top: 5px;

        a {
          color: abstracts.$secondary-main  !important;
        }
      }


    }

    &-left {
      display: flex;
      flex-direction: column;
      padding-bottom: 0;

      &-text {
        margin-top: 5px;
      }

      @media screen and (max-width: abstracts.$medium) {
        &-text {
          font-size: initial !important;
          margin-top: 10px;
        }
      }
    }

    &-right {
      @media screen and (max-width: abstracts.$medium) {
        padding-top: 0;

        &-cont {
          margin-bottom: 5px;
        }
      }
    }
  }

  &__grid-down-container {
    border-top: 2px solid abstracts.$primary-main;
    padding: 15px;
    color: abstracts.$gray-500;

    @media screen and (min-width: 962px) and (max-width:1340px){
      justify-content: space-between;
    }

    @media screen and (max-width: abstracts.$medium) {
      //flex-direction: column;
      align-items: flex-start;
      padding: 0;

      h4 {
        padding: 10px 0;
        text-align: center;
        width: 100%;
        border-bottom: 1px solid #EEEEEE;
        color: #EEEEEE;
        font-weight: 300;
      }
    }

    &-left,
    &-right {
      @include abstracts.flexbox-vertical-align;
      width: 50%;
      @media screen and (max-width: abstracts.$medium) {
        justify-content: center;
        width: 100%;
      }

      &-icon {
        display: flex;
        align-items: center;

        @media screen and (max-width: abstracts.$medium) {
          margin-top: 15px;
          padding-left: 15px;

          span {
            //font-size: .8rem;
          }
        }
      }

      &-image {
        width: 18px;
        height: 18px;
        margin-top: 3px;
      }

      @media screen and (max-width: abstracts.$medium) {
        a {
          //font-size: .8rem;
          padding-left: 15px;
        }
      }
    }

    &-left {
      h4 {
        color: #333333;
      }

      @media screen and (min-width: 962px) and (max-width:1340px){
        max-width: fit-content;
      }

      @media screen and (max-width: abstracts.$medium) {
        display: block;
      }
    }

    &-right {
      @include abstracts.flexbox-vertical-align;
      justify-content: space-between;

      @media screen and (min-width: 962px) and (max-width:1340px){
        min-width: 68%;
      }

      @media screen and (max-width: abstracts.$medium) {
        flex-direction: column;
        align-items: baseline;
        margin-bottom: 15px;

        .MuiFormControl-root {
          margin-top: 0 !important;
        }
      }

      &-typo {
        padding-left: 5px;
      }

      a {
        color: abstracts.$secondary-main  !important;
      }
    }
  }
}