
@font-face {
  font-family: "Open Sans Light";
  font-style: normal;
  src: local("Open Sans Light"),
  local("OpenSans-Light"),
  url("https://cdn.homedepot.com.mx/assets/fonts/Open_Sans/OpenSans-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  src: local("Open Sans Regular"),
  local("OpenSans-Regular"),
  url("https://cdn.homedepot.com.mx/assets/fonts/Open_Sans/OpenSans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Open Sans SemiBold";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans SemiBold"),
  local("OpenSans-SemiBold"),
  url("https://cdn.homedepot.com.mx/assets/fonts/Open_Sans/OpenSans-SemiBold.woff2") format("woff2");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"),
  local("OpenSans-Bold"),
  url("https://cdn.homedepot.com.mx/assets/fonts/Open_Sans/OpenSans-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "HelveticaNeue_THD";
  font-style: normal;
  font-weight: 400;
  src: local("Helvetica Neue LT Std"),
  url("https://cdn.homedepot.com.mx/assets/fonts/HelveticaNeueLTStd-HvCn.woff2") format("woff2");
}

@font-face {
  font-family: "HelveticaNeue_THD";
  font-style: normal;
  font-weight: 600;
  src: local("Helvetica Neue LT Std"),
  url("https://cdn.homedepot.com.mx/assets/fonts/HelveticaNeueLTStd-BlkCn.woff2") format("woff2");
}
